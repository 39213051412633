import React, { useEffect, useRef, useState } from "react";

import { Row, Modal, Button, Col, Card } from "react-bootstrap";
import ScrollToTop from "../../../ScrollToTop";
import NavbarDashboard from "../../NavbarDashboard";
import { Link, useHistory, useParams } from "react-router-dom";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { setType, setTitle } from "../../../../redux/reducers/typeSlice";
import { Editor } from '@tinymce/tinymce-react';
const TemplateDetails = () => {
  const [show, setShow] = useState(false);
  const templateName = useSelector((state) => state.type.templateName);
  const [categoryName, setCategoryName] = useState("");
  const [categoryType, setCategoryType] = useState("");
  const [tempUpdate, setTempUpdate] = useState(false);
  const history = useHistory();
  const [categories, setCategories] = useState([]);
  const [selectedIcon, setSelectedIcon] = useState("");
  const [hoveredIndex, setHoveredIndex] = useState(null);
  const [editingIndex, setEditingIndex] = useState(null);
  const [limitations, setLimitations] = useState("");
  const { id } = useParams();
  let token = localStorage.getItem("access_token");
  const editorRef = useRef(null);
  const handleClose = () => {
    setShow(false);
    setEditingIndex(null);
  };
  const handleEditorChange = (content, editor) => {
    setLimitations(content);
  };

  const handleShow = () => setShow(true);

  const handleCategoryNameChange = (event) => {
    setCategoryName(event.target.value);
  };
  const handleIconSelect = (event) => {
    setSelectedIcon(event.target.value);
  };
  const handleCategoryTypeSelect = (type) => {
    setCategoryType(type);
  };

  const fetchCategories = async () => {
    document.body.classList.add("loading-indicator");
    const config = {
      method: "post",
      url: "/v1/oauth/templates/step-list",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      data: {
        template_id: id,
      },
    };

    try {
      const response = await axios(config);
      setCategories(response.data.data);
  
      document.body.classList.remove("loading-indicator");
    } catch (error) {
      console.error("Error fetching categories:", error.response || error);
      document.body.classList.remove("loading-indicator");
    }
  };

  useEffect(() => {
    fetchCategories();
  }, [tempUpdate]);

  const createTemplateStep = async (templateId, title, icon, type) => {
    document.body.classList.add("loading-indicator");
    const config = {
      method: "post",
      url: "/v1/oauth/templates/step/create",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      data: {
        template_id: templateId,
        title: title,
        icon: icon,
        optional_or_include: type,
        limitations: limitations,
      },
    };

    try {
      const response = await axios(config);
    
      setTempUpdate(!tempUpdate);
      document.body.classList.remove("loading-indicator");
    } catch (error) {
      console.error("Error creating template step:", error.response || error);
      document.body.classList.remove("loading-indicator");
    }
    setCategoryName("");
    setCategoryType("");
    setSelectedIcon("");
    handleClose();
  };

  const startEditCategory = (index, id) => {
    const category = categories[index];
    setEditingIndex(index);
    setCategoryName(category.title);
    setCategoryType(category.type === "optional" ? "option1" : "option2");
    setSelectedIcon(category.icon);
    setLimitations(category.limitations || "");
    handleShow();
  };

  const updateTemplateStep = async (
    step_id,
    title,
    icon,
    type,
    template_id
  ) => {
    document.body.classList.add("loading-indicator");
    const config = {
      method: "post",
      url: "/v1/oauth/templates/step/update",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      data: {
        template_id: template_id,
        template_step_id: step_id,
        title: title,
        icon: icon,
        optional_or_include: type,
        limitations: limitations,
      },
    };

    try {
      const response = await axios(config);
  
      setTempUpdate(!tempUpdate);
      document.body.classList.remove("loading-indicator");
    } catch (error) {
      console.error("Error updating template step:", error.response || error);
      document.body.classList.remove("loading-indicator");
    }
  };

  const addOrUpdateCategory = () => {
    const newCategory = {
      name: categoryName,
      type: categoryType,
      icon: selectedIcon,
      limitations: limitations,
    };

    const templateId = id;
    const title = newCategory.name;
    const icon = newCategory.icon;
    const type = newCategory.type === "option1" ? "optional" : "included";

    if (editingIndex !== null) {
      const step_id = categories[editingIndex].id;
      const template_Id = id;

      updateTemplateStep(step_id, title, icon, type, template_Id, limitations);
    } else {
      createTemplateStep(templateId, title, icon, type, limitations);
    }

    setCategoryName("");
    setCategoryType("");
    setSelectedIcon("");
    setLimitations("");
    handleClose();
  };


  const deleteCategory = async (step_id) => {
    document.body.classList.add("loading-indicator");
    const config = {
      method: "post",
      url: "/v1/oauth/templates/step/delete",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      data: {
        template_step_id: step_id,
        template_id: id,
      },
    };

    try {
      const response = await axios(config);

      document.body.classList.remove("loading-indicator");
      setTempUpdate(!tempUpdate);
    } catch (error) {
      console.error("Error deleting category:", error.response || error);
    }
  };

  const dispatch = useDispatch();

  const handleLinkClick = (type, title) => {
    dispatch(setType(type));
    dispatch(setTitle(title));
  };
// handle icon dropdown
const [isDropdownOpen, setIsDropdownOpen] = useState(false);
const dropdownRef = useRef(null);

const icons = [
  "insulation.png",
  "cooling.png",
  "interior.png",
  "roofing.png",
  "exterior.png",
  "appliance.png",
  "fireplaces.png",
  "overallpropertdescription.png",
  "electrical.png",
  "heating.png",
  "plumbing.png",
  "structure.png",
];

useEffect(() => {
  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsDropdownOpen(false);
    }
  };

  document.addEventListener('mousedown', handleClickOutside);
  return () => {
    document.removeEventListener('mousedown', handleClickOutside);
  };
}, []);




  return (
    <>
      <ScrollToTop />
      <NavbarDashboard />
      <div className="content-container Mcontent">
        <Row className="side-spacing pt50">
          <div className="mb20 list-header border-bottom">
            <div className="float-start d-flex justify-content-center align-items-center">
              <img
                src="/assets/arrow-fwd-lg.png"
                style={{ transform: "rotate(180deg)", cursor: "pointer" }}
                alt=""
                onClick={() => history.goBack()}
              />
              <h2 className="mt-2 ml10">{templateName || "Category"}</h2>
            </div>
            <div
              className="position-relative float-end search-list d-flex align-items-center justify-content-center mt-2 btn"
              onClick={handleShow}
            >
              <img
                src="/assets/icon/addicon.png"
                className="ml20 cursor-pointer mr10"
                alt=""
              />
              <div>Add Category</div>
            </div>
          </div>
          {categories.map((data, index) => (
            <Col sm={4} lg={3} key={index}>
              <Link
                to={`/template-steps/${id}/${data.id}`}
                style={{ textDecoration: "none", color: "black" }}
                onClick={() =>
                  handleLinkClick(data.optional_or_include, data.title)
                }
                onMouseEnter={() => setHoveredIndex(index)}
                onMouseLeave={() => setHoveredIndex(null)}
              >
                <Card className="card-custom mb-4 cursor-pointer">
                  <Card.Body className="text-center d-flex flex-column justify-content-between">
                    <div className="inspStepImgCt">
                      <div className="position-relative">
                        <img
                          src={`/assets/inspectionSteps/${data.icon}`}
                          className="menuCard-img"
                          alt={data.name}
                        />
                        {hoveredIndex === index && (
                          <div
                            style={{
                              position: "absolute",
                              top: "-26px",
                              right: "-8px",
                              display: "flex",
                            }}
                          >
                            <img
                              src="/assets/icon/editicon.png"
                              alt="Edit"
                              style={{
                                cursor: "pointer",
                                width: "18px",
                                height: "18px",
                                marginRight: "8px",
                              }}
                              onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                startEditCategory(index);
                              }}
                            />
                            <img
                              src="/assets/icon/delete.png"
                              alt="Delete"
                              style={{
                                cursor: "pointer",
                                width: "18px",
                                height: "18px",
                              }}
                              onClick={(e) => {
                                e.preventDefault(); // Prevent navigation
                                e.stopPropagation(); // Stop event bubbling
                                deleteCategory(data.id);
                              }}
                            />
                          </div>
                        )}
                      </div>
                    </div>
                    <h6 className="mt-2">{data.title}</h6>
                  </Card.Body>
                </Card>
              </Link>
            </Col>
          ))}
        </Row>
      </div>

      <Modal show={show} onHide={handleClose} className="p-3">
        <Modal.Header
          closeButton
          className="my-2"
          style={{ borderBottom: "none" }}
        >
          <Modal.Title>Add a New Category</Modal.Title>
        </Modal.Header>
        <Modal.Body className="p-4 w-100">
          <div className="form-group">
            <label htmlFor="categoryName">Category Name:</label>
            <input
              type="text"
              className="form-control"
              id="categoryName"
              value={categoryName}
              onChange={handleCategoryNameChange}
              style={{ border: "none", borderBottom: "1px solid #CDCDCD" }}
            />
          </div>
          <div className="form-group my-2">
            <label htmlFor="categoryType">Optional/Included</label>
            <select
              className="form-control position-relative"
              id="categoryType"
              value={categoryType}
              style={{ border: "none", borderBottom: "1px solid #CDCDCD" }}
              onChange={(e) => handleCategoryTypeSelect(e.target.value)}
            >
              <option value="">Select</option>
              <option value="option1">
                Optional -add on a per-report base
              </option>
              <option value="option2">Include in every report</option>
            </select>
            <img
              src="/assets/icon/angle-down.png"
              alt=""
              style={{ position: "absolute", right: "6%", top: "53%" }}
            />
          </div>
          <div className="form-group w-100 position-relative">
          <div className="form-group">
            <label htmlFor="iconSelect">Select Icon:</label>
            <div 
              ref={dropdownRef}
              className="custom-dropdown"
              style={{ 
                border: 'none', 
                borderBottom: '1px solid #CDCDCD',
                cursor: 'pointer',
                position: 'relative'
              }}
            >
              <div 
                className="selected-option" 
                onClick={() => setIsDropdownOpen(!isDropdownOpen)}
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  padding: '10px'
                }}
              >
                {selectedIcon ? (
                  <img src={`/assets/inspectionSteps/${selectedIcon}`} alt={selectedIcon} style={{ width: '30px', height: '30px', marginRight: '10px' }} />
                ) : (
                  <span>Select Icon</span>
                )}
                <img
                  src="/assets/icon/angle-down.png"
                  alt=""
                  style={{ position: 'absolute', right: '10px', top: '50%', transform: 'translateY(-50%)' }}
                />
              </div>
              {isDropdownOpen && (
                <div className="options" style={{
                  position: 'absolute',
                  top: '100%',
                  left: 0,
                  right: 0,
                  backgroundColor: 'white',
                  border: '1px solid #ccc',
                  zIndex: 1000,
                  maxHeight: '200px',
                  overflowY: 'auto'
                }}>
                  {icons.map((icon, index) => (
                    <div 
                      key={index} 
                      onClick={() => {
                        handleIconSelect({ target: { value: icon } });
                        setIsDropdownOpen(false);
                      }}
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        padding: '10px',
                        cursor: 'pointer'
                      }}
                    >
                      <img src={`/assets/inspectionSteps/${icon}`} alt={icon} style={{ width: '30px', height: '30px', marginRight: '10px' }} />
                      {icon}
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>
        </div>
        <label htmlFor="limitations" className="mt-10px">Limitations:</label>
        <Editor
        onInit={(evt, editor) => editorRef.current = editor}
        apiKey={process.env.REACT_APP_TINY_MCE_KEY}
        value={limitations}
        onEditorChange={handleEditorChange}
        init={{
          height: 500,
          menubar: false,
          plugins: [
           'list', 'advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'preview', 'anchor', 'pagebreak'
          ],
          toolbar:
            'bullist numlist | formatselect | bold italic forecolor backcolor | alignleft aligncenter alignright alignjustify | fontsizeselect styleselect blockquote hr',
          toolbar_mode: 'sliding',
          content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
          quickbars_selection_toolbar: 'bold italic | quicklink h2 h3 blockquote quickimage quicktable'
        }}
      />
          {/* <div className="form-group w-100 position-relative">
            <div className="form-group">
              <label htmlFor="limitations">Limitations:</label>
              <textarea
                name="limitations"
                id="limitations"
                rows="6"
                className="form-control"
                style={{
                  width: "100%",
                  minHeight: "120px",
                  resize: "vertical",
                }}
                value={limitations}
                onChange={(e) => setLimitations(e.target.value)}
              ></textarea>
            </div>
          </div> */}
        </Modal.Body>
        <Modal.Footer className="my-2" style={{ borderTop: "none" }}>
          <Button
            className="m-0 w-25 btn-submit mx-auto "
            variant="primary"
            onClick={addOrUpdateCategory}
          >
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default TemplateDetails;
